
export default {
  layout: 'home',
  async asyncData({ $getContent }) {
    const features = await $getContent('features')
    const images = []
    for (const index of Array(features.length).keys()) {
      images.push(require(`~/assets/images/feature-${index + 1}.png`))
    }

    return { features, images }
  },
}
